import React, { useState } from 'react';
import { useRouter } from 'next/router';

const DnsCheckForm = ({ onSubmit, isLoading, onNavigate }) => {
  const [inputDomain, setInputDomain] = useState('');
  const router = useRouter();

  const handleSubmit = (e) => {
    e.preventDefault();
    if (onNavigate) {
      onNavigate(inputDomain);
    } else {
      onSubmit(inputDomain);
    }
  };

  const handleDomainChange = (e) => {
    setInputDomain(e.target.value);
  };

  return (
    <div className="inner-container _600px w-100">
      <div className="form-button-inside-block w-form">
        <form
          onSubmit={handleSubmit}
          name="email-form"
          className="form-button-inside mg-bottom-16px"
        >
          <input
            type="text"
            className="input button-inside w-input"
            maxLength={256}
            name="Domain"
            placeholder="Enter your domain name"
            value={inputDomain}
            onChange={handleDomainChange}
            required
          />
          <div className="btn-primary inside-input default-with-arrow">
            <input
              type="submit"
              value="Check Now"
              data-wait="Please wait..."
              className="email-btn-form-inside-btn w-button"
              disabled={isLoading}
            />
            <div className="line-rounded-icon link-icon-right"></div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default DnsCheckForm;
