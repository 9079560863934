import React, { useState, useRef } from 'react';
import { useRouter } from 'next/router';
import Image from 'next/image';
import Link from 'next/link';
import DnsCheckForm from '@/components/DnsCheckForm';
import Carousel from '@/components/Carousel';
import { Modal, Button } from 'antd';
import Head from 'next/head';
import AnimatedGrid from '@/components/AmimateGrid';
import AnimatedButton from '@/components/AnimatedButton';
import BimiInteractive from '@/components/BimiInteractive';
import styles from './styles/index.module.scss';
import { motion, useScroll, useTransform, useInView } from 'framer-motion';

export const metadata = {
  title: 'Brandymail',
  description: 'Brand your emails. Get more opens.',
};

const Home = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const videoRef = useRef(null);
  const router = useRouter();
  const ref = useRef(null);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
    if (videoRef.current) {
      videoRef.current.pause();
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    if (videoRef.current) {
      videoRef.current.pause();
    }
  };

  const handleNavigate = (domain) => {
    router.push(`/bimi-checker?domain=${domain}`);
  };

  return (
    <div>
      <Head>
        <title>Verified & Common Mark Certificates for Email Trust & Security | Brandymail</title>
        <meta
          name="description"
          content="Boost email trust and brand visibility with Verified Mark Certificates & Common Mark Certificates. Enhance your emails using BIMI, VMC, CMC, and DMARC enforcement for better email delivery and brand visibility & protection."
        />
        <link rel="canonical" href="https://brandymail.com/" />

        {/* Open Graph / Facebook */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://brandymail.com/" />
        <meta
          property="og:title"
          content="Verified & Common Mark Certificates for Email Trust & Security | Brandymail"
        />
        <meta
          property="og:description"
          content="Boost email trust and brand visibility with Verified Mark Certificates & Common Mark Certificates. Enhance your emails using BIMI, VMC, CMC, and DMARC enforcement for better email delivery and brand visibility & protection."
        />
        <meta
          property="og:image"
          content="https://brandymail.com/images/og-digicert-verified-mark-certificates.png"
        />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
        <meta
          property="og:image:alt"
          content="DigiCert Verified Mark Certificate (VMC) - Display Your Logo in Gmail"
        />

        {/* Twitter */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:url" content="https://brandymail.com/" />
        <meta
          name="twitter:title"
          content="Verified & Common Mark Certificates for Email Trust & Security | Brandymail"
        />
        <meta
          name="twitter:description"
          content="Boost email trust and brand visibility with Verified Mark Certificates & Common Mark Certificates. Enhance your emails using BIMI, VMC, CMC, and DMARC enforcement for better email delivery and brand visibility & protection."
        />
        <meta
          name="twitter:image"
          content="https://brandymail.com/images/twitter-digicert-verified-mark-certificates.png"
        />
        <meta
          name="twitter:image:alt"
          content="DigiCert Verified Mark Certificate (VMC) - Display Your Logo in Gmail"
        />

        {/* Additional SEO meta tags */}
        <meta name="robots" content="index, follow" />
        <meta
          name="keywords"
          content="VMC, CMC, BIMI, DMARC, email security, brand protection, email authentication, verified mark certificate, common mark certificate"
        />
        <meta name="author" content="Brandymail" />

        {/* Structured Data */}
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify({
              '@context': 'https://schema.org',
              '@type': 'Organization',
              name: 'Brandymail',
              url: 'https://brandymail.com',
              logo: 'https://brandymail.com/images/logo-light.svg',
              description:
                'Boost email trust and brand visibility with Verified Mark Certificates & Common Mark Certificates.',
              sameAs: [
                'https://twitter.com/brandymail',
                'https://www.linkedin.com/company/brandymail',
              ],
            }),
          }}
        />
      </Head>

      <section className="section hero v1">
        <div className="container-default w-container">
          <div className="position-relative z-index-1">
            <div className="mg-bottom-93px">
              <div className="inner-container _900px center">
                <div className="inner-container _500px---mbl center">
                  <div className="text-center">
                    <motion.h1 className="display-1 color-neutral-100">
                      <motion.span
                        initial={{ x: -100, opacity: 0 }}
                        animate={{ x: 0, opacity: 1 }}
                        transition={{ duration: 0.8 }}
                      >
                        Brand your emails.
                      </motion.span>{' '}
                      <motion.span
                        className="text-no-wrap"
                        initial={{ x: 100, opacity: 0 }}
                        animate={{ x: 0, opacity: 1 }}
                        transition={{ duration: 0.8 }}
                      >
                        Get more opens.
                      </motion.span>
                    </motion.h1>
                    <p className="color-neutral-300 mg-bottom-40px paragraph-large">
                      Buy a DigiCert Mark Certificate to add your logo and/or blue checkmark to
                      emails you send. Build instant trust with your email recipients by letting
                      them know it's really you.
                    </p>

                    <div className="buttons-row center">
                      <AnimatedButton
                        href="/products/digicert-verified-mark-certificate"
                        target="_self"
                        text="Buy Now"
                      />

                      <Link href="#why" className="btn-secondary white w-button">
                        Learn more
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="position-relative">
              <div
                className="inner-container _85 center"
                style={{ position: 'sticky', top: '20%' }}
              >
                <div ref={ref} className={styles.heroImageContainer}>
                  {/* <div className={styles.guideImage}></div> */}

                  <motion.div
                    initial={{ opacity: 0, y: 50 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.8, delay: 0.2 }}
                    className={styles.heroBrowserContainer}
                  >
                    <Image
                      src="/images/hero-browser.svg"
                      alt="Browser window showcasing Brandymail's DMARC Inspector tool for verifying email compliance"
                      layout="fill"
                      objectFit="contain"
                      priority
                    />
                  </motion.div>

                  <motion.div
                    initial={{ opacity: 0, x: -50 }}
                    whileInView={{ opacity: 1, x: 0 }}
                    transition={{ duration: 0.8, delay: 0.4 }}
                    viewport={{ once: true, amount: 0.5 }}
                    className={styles.heroBoaContainer}
                  >
                    <Image
                      src="/images/hero-boa.svg"
                      alt="Bank of America logo"
                      layout="fill"
                      objectFit="contain"
                    />
                  </motion.div>

                  <motion.div
                    initial={{ opacity: 0, x: 50 }}
                    whileInView={{ opacity: 1, x: 0 }}
                    transition={{ duration: 0.8, delay: 0.6 }}
                    viewport={{ once: true, amount: 0.5 }}
                    className={styles.heroIphoneContainer}
                  >
                    <Image
                      src="/images/hero-iphone.svg"
                      alt="iPhone displaying Brandymail DMARC Inspector tool for email compliance"
                      layout="fill"
                      objectFit="contain"
                      priority
                      className={styles.heroIphone}
                    />
                  </motion.div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="position-absolute dark-fade---button"></div>
      </section>

      <div className="overflow-hidden border-radius-40px bg-neutral-100">
        <section className="section pd-100px">
          <div className="inner-container _989px _600px---mbl center mg-bottom-24px">
            <div className="text-center _712px">
              <div className="mg-bottom-24px">
                <div className="text-100 bold color-accent-1 text-uppercase">
                  Verified Marks In Use
                </div>
              </div>
              <h2 className="display-2">The world's largest companies use VMC</h2>
              <div className="inner-container _989px center paragraph-large">
                <p style={{ marginBottom: '64px' }}>
                  These top companies adopted VMCs to increase brand visibility and trust, now you
                  can too with Common Mark Certificates (CMC). Click on a logo below to see the
                  details of their VMC.
                </p>
              </div>
            </div>
          </div>
          <Carousel enableLinks={true} />
        </section>
        <div className="container-default w-container">
          <div className="divider _0px"></div>
        </div>
        <section id="why" className="section pd-188px">
          <div className="container-default w-container">
            <div className="inner-container _700px---tablet center">
              <div className="inner-container _600px---mbl center">
                <div className="w-layout-grid grid-2-columns section-features-grid">
                  <div className="position-relative bg-grid-white section-features">
                    <div className="position-relative z-index-1 height-100">
                      <div className="flex-horizontal align-center height-100">
                        <div className="inner-container _100---tablet width-100">
                          <div className="position-relative section-features">
                            <div className="inner-container">
                              <div className="image-wrapper border-radius-18px">
                                <Image
                                  src="/images/brandymail-bimi-vmc-home2b.svg"
                                  loading="eager"
                                  alt="Brandymail logo illustrating the display of a verified brand logo in email clients, enhancing trust and brand experience"
                                  className="image cover"
                                  height={0}
                                  width={0}
                                  priority
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="position-absolute white-fade"></div>
                  </div>
                  <div className="inner-container _554px">
                    <div className="mg-bottom-24px">
                      <div className="text-100 bold color-accent-1 text-uppercase">Why VMC?</div>
                    </div>
                    <h2 className="display-2 mg-bottom-24px keep" style={{ marginBottom: '48px' }}>
                      Increase open rates by up to 30%
                    </h2>
                    <div className="w-layout-grid grid-1-column gap-row-80px">
                      <div className="icon-left-content" style={{ marginBottom: '24px' }}>
                        <div className="inner-container _29px width-100">
                          <div className="image-wrapper">
                            <Image
                              src="/images/icon-1-features-section-techkit-template.svg"
                              loading="eager"
                              alt="Advanced Tracking - Brandymail.com"
                              className="image cover"
                              height={0}
                              width={0}
                            />
                          </div>
                        </div>
                        <div>
                          <h3 className="text-300 bold color-neutral-800">
                            Display your verified brand logo in email clients
                          </h3>
                          <p className="mg-bottom-0">
                            Build and maintain trust with your customers &amp; prospects. Extend
                            your brand experience to the inbox to differentiate from imposters and
                            phishing attempts.
                          </p>
                        </div>
                      </div>
                      <div className="icon-left-content">
                        <div className="inner-container _29px width-100">
                          <div className="image-wrapper">
                            <Image
                              src="/images/icon-1-blue-check.svg"
                              loading="eager"
                              alt="In-depth Monitoring - Brandymail.com"
                              className="image cover"
                              height={0}
                              width={0}
                            />
                          </div>
                        </div>
                        <div>
                          <h3 className="text-300 bold color-neutral-800">
                            Activate the Gmail blue checkmark
                          </h3>
                          <p className="mg-bottom-0">
                            Provide at-a-glance proof to Gmail users that you are you. Adding the
                            world-renowned blue checkmark will showcase you've been verified.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div style={{ paddingLeft: '46px', marginTop: '48px' }}>
                      <div className="buttons-row">
                        <AnimatedButton
                          href="/products/digicert-verified-mark-certificate"
                          target="_blank"
                          text="Buy Now"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <section className="section">
        <div className="container-default w-container">
          <div className="inner-container _700px---tablet center">
            <div className="inner-container _600px---mbl center">
              <div className="mg-bottom-80px">
                <div className="inner-container _710px center">
                  <div className="inner-container _600px---tablet center">
                    <div className="inner-container _500px---mbl center">
                      <div className="text-center">
                        <div className="mg-bottom-24px">
                          <div className="text-100 bold color-accent-1 text-uppercase">
                            Benefits
                          </div>
                        </div>
                        <h2 className="display-2 color-neutral-100">
                          Your marketing &amp; IT teams will love the benefits of VMC!
                        </h2>
                        <p className="color-neutral-300 paragraph-medium">
                          Marketing teams love the enhanced brand experience. IT teams love the
                          enhanced email security with DMARC enforcement. They both love working
                          with Brandymail™ to show them all the shortcuts.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-layout-grid grid-2-columns gap-40px">
                <div className="card feature-featured" id="home-feature-card">
                  <div className="card-feature-featured-content">
                    <h3 className="display-3 color-neutral-100 mg-bottom-16px">
                      Increase Brand Impressions
                    </h3>
                    <p className="color-neutral-200 mg-bottom-40px">
                      Your brand logo is seen by all recipients, even if they don't open an
                      email—you'll stay top of mind.
                    </p>
                    <div className="buttons-row">
                      <Link href="/contact" className="btn-secondary white w-button">
                        Learn more
                      </Link>
                    </div>
                  </div>
                  <div className="card-feature-featured-images">
                    <div className="inner-container">
                      <div className="card-feature-featured---image-01">
                        <div className="image-wrapper border-radius-18px shadow">
                          <motion.div
                            initial={{ x: 100, opacity: 0 }}
                            whileInView={{ x: 0, opacity: 1 }}
                            transition={{ duration: 0.5, delay: 0.3, ease: 'easeInOut' }}
                            viewport={{ once: true }}
                          >
                            <Image
                              src="/images/brandymail-bimi-vmc-home3.svg"
                              loading="eager"
                              alt="Advanced Analytics - Brandymail.com"
                              className="image cover"
                              height={0}
                              width={0}
                            />
                          </motion.div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-layout-grid grid-2-columns gap-40px feature-card-wrapper">
                <div className="card feature-dark">
                  <div className="card-feature-dark-content">
                    <h3 className="display-3 color-neutral-100 mg-bottom-16px">
                      Unify Messaging &amp; Experience
                    </h3>
                    <p className="color-neutral-600 mg-bottom-0 paragraph-medium">
                      Instantly deliver a more recognizable and unified brand experience in all
                      email communications.
                    </p>
                  </div>
                  <div className="card-feature-dark-images">
                    <div className="inner-container center">
                      <div className="card-feature-dark---image-01">
                        <motion.div
                          className="image-wrapper border-radius-18px"
                          initial={{ y: 100, opacity: 0 }}
                          whileInView={{ y: 0, opacity: 1 }}
                          transition={{ duration: 0.5, delay: 0.3, ease: 'easeInOut' }}
                          viewport={{ once: true }}
                        >
                          <Image
                            src="/images/image-unified-messaging.svg"
                            loading="eager"
                            alt="Unify Messaging & Experience"
                            className="image cover"
                            height={0}
                            width={0}
                          />
                        </motion.div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card feature-light">
                  <div className="card-feature-light-content">
                    <h3 className="display-3 mg-bottom-16px">
                      Showcase Authenticity &amp; Security
                    </h3>
                    <p className="color-neutral-600 mg-bottom-0 paragraph-medium">
                      Instantly add visual trust indicators for using the highest level of
                      authentication and security best practices.
                    </p>
                  </div>
                  <div className="card-feature-light-images">
                    <div className="inner-container center">
                      <motion.div
                        className="image-wrapper border-radius-18px"
                        initial={{ y: 100, opacity: 0 }}
                        whileInView={{ y: 0, opacity: 1 }}
                        transition={{ duration: 1, delay: 0.5, ease: 'easeInOut' }}
                        viewport={{ once: true }}
                      >
                        <Image
                          src="/images/brandymail-bimi-vmc-home2.svg"
                          loading="eager"
                          alt="Showcase Authenticity & Security"
                          className="image cover"
                          height={0}
                          width={0}
                        />
                      </motion.div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="section awards border-radius-40px mg-bottom-200px">
        <div className="container-default w-container">
          <div className="inner-container center">
            <div className="w-layout-grid grid-2-columns">
              <div className="left-column">
                <Image
                  src="/images/logo-DigiCert.svg"
                  alt="DigiCert Logo"
                  width={180}
                  height={100}
                  className="digicert-logo"
                />
                <p className="color-neutral-700 paragraph-medium">
                  For over 25 years, DigiCert has been the global leader in digital trust. With its
                  continuous commitment to innovation and developing new standards, DigiCert led the
                  BIMI Group and became the first authorized provider of Verified Mark Certificates
                  in the world. Now, you can get one from us.
                </p>
              </div>
              <div className="right-column">
                <div className="w-layout-grid grid-4-columns">
                  <motion.div
                    className="award-item"
                    whileInView={{ opacity: 1 }}
                    initial={{ opacity: 0 }}
                    transition={{ duration: 0.3, delay: 0 }}
                  >
                    <Image
                      src="/images/digicert-info-sec-award-2024.png"
                      alt="DigiCert Info Sec Award 2024"
                      width={150}
                      height={50}
                      className="award-image"
                    />
                  </motion.div>
                  <motion.div
                    className="award-item"
                    whileInView={{ opacity: 1 }}
                    initial={{ opacity: 0 }}
                    transition={{ duration: 0.3, delay: 0.1 }}
                  >
                    <Image
                      src="/images/digicert-cio-bulletin-2024.png"
                      alt="DigiCert CIO Bulletin 2024"
                      width={150}
                      height={50}
                      className="award-image"
                    />
                  </motion.div>
                  <motion.div
                    className="award-item"
                    whileInView={{ opacity: 1 }}
                    initial={{ opacity: 0 }}
                    transition={{ duration: 0.3, delay: 0.2 }}
                  >
                    <Image
                      src="/images/digicert-2022-silvie-award.png"
                      alt="DigiCert 2022 Silvie Award"
                      width={150}
                      height={50}
                      className="award-image"
                    />
                  </motion.div>
                  <motion.div
                    className="award-item"
                    whileInView={{ opacity: 1 }}
                    initial={{ opacity: 0 }}
                    transition={{ duration: 0.3, delay: 0.3 }}
                  >
                    <Image
                      src="/images/digicert-2021-security100.png"
                      alt="DigiCert 2021 Security 100"
                      width={150}
                      height={50}
                      className="award-image"
                    />
                  </motion.div>
                  <motion.div
                    className="award-item"
                    whileInView={{ opacity: 1 }}
                    initial={{ opacity: 0 }}
                    transition={{ duration: 0.3, delay: 0.4 }}
                  >
                    <Image
                      src="/images/digicert-iot-breakthrough-award-badge-2021.png"
                      alt="DigiCert IoT Breakthrough Award Badge 2021"
                      width={150}
                      height={50}
                      className="award-image"
                    />
                  </motion.div>
                  <motion.div
                    className="award-item"
                    whileInView={{ opacity: 1 }}
                    initial={{ opacity: 0 }}
                    transition={{ duration: 0.3, delay: 0.5 }}
                  >
                    <Image
                      src="/images/digicert-2022-security100.png"
                      alt="DigiCert 2022 Security 100"
                      width={150}
                      height={50}
                      className="award-image"
                    />
                  </motion.div>
                  <motion.div
                    className="award-item"
                    whileInView={{ opacity: 1 }}
                    initial={{ opacity: 0 }}
                    transition={{ duration: 0.3, delay: 0.6 }}
                  >
                    <Image
                      src="/images/digicert-best-in-biz-2020-silver-med.png"
                      alt="DigiCert Best in Biz 2020 Silver Medal"
                      width={150}
                      height={50}
                      className="award-image"
                    />
                  </motion.div>
                  <motion.div
                    className="award-item"
                    whileInView={{ opacity: 1 }}
                    initial={{ opacity: 0 }}
                    transition={{ duration: 0.3, delay: 0.7 }}
                  >
                    <Image
                      src="/images/digicert-award-logo.png"
                      alt="DigiCert Award Logo"
                      width={150}
                      height={50}
                      className="award-image"
                    />
                  </motion.div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="position-relative z-index-1">
        <section className="section pd-0px position-relative">
          <div className="container-default w-container">
            <div className="cta-v1-wrapper">
              <div className="inner-container">
                <div className="position-relative z-index-1">
                  <div className="inner-container _400px---mbl">
                    <h2 className="display-2 color-neutral-100">Is your domain BIMI ready?</h2>
                    <div className="inner-container _636px">
                      <p className="color-neutral-200 mg-bottom-40px paragraph-medium">
                        Use our BIMI checker tool to see if BIMI is configured properly on your
                        domain(s). Let's get your BIMI right to increase email deliverability,
                        differentiate from impersonators, and improve your brand experience.
                      </p>
                    </div>
                  </div>
                  <DnsCheckForm onNavigate={handleNavigate} />
                </div>
              </div>
              <div className="position-absolute cta-grid-wrapper">
                {/* <div className="position-absolute cta-grid-fade"></div> */}
                <AnimatedGrid />
              </div>
            </div>
          </div>
          <div className="position-absolute cta-v1-bg"></div>
        </section>
      </div>

      <BimiInteractive />

      <div className="bg-neutral-100">
        <div className="overflow-hidden border-radius-40px">
          <section className="section light">
            <div className="container-default w-container">
              <div className="inner-container _600px---tablet center">
                <div className="inner-container _500px---mbl center">
                  <div className="_2-grid-wrapper">
                    <div className="w-layout-grid grid-2-columns text-left-default mg-bottom-78px">
                      <motion.div
                        className="inner-container _554px _100---tablet"
                        initial={{ opacity: 0, x: -100 }}
                        whileInView={{ opacity: 1, x: 0 }}
                        transition={{ duration: 0.5, delay: 0.1 }}
                        viewport={{ once: true }}
                      >
                        <div className="text-center---tablet">
                          <div className="mg-bottom-24px">
                            <div className="text-100 bold color-accent-1 text-uppercase">
                              Secure Messaging
                            </div>
                          </div>
                          <h2 className="display-2">Reduce Phishing Attacks</h2>
                          <p className="mg-bottom-40px paragraph-medium">
                            You'll become less of a spoofing or phishing target thanks to DMARC and
                            a visible logo differentiator.
                          </p>
                          <div className="buttons-row center---tablet">
                            <AnimatedButton
                              href="/products/digicert-verified-mark-certificate"
                              target="_blank"
                              text="Get Started"
                            />
                          </div>
                        </div>
                      </motion.div>
                      <motion.div
                        className="inner-container _609px width-100 _100---tablet"
                        initial={{ opacity: 0, x: 100 }}
                        whileInView={{ opacity: 1, x: 0 }}
                        transition={{ duration: 0.5, delay: 0.3 }}
                        viewport={{ once: true }}
                      >
                        <div className="position-relative">
                          <div className="inner-container _87 mg-left-auto">
                            <div className="image-wrapper border-radius-18px">
                              <Image
                                src="/images/brandymail-bimi-vmc-home5.svg"
                                loading="eager"
                                alt="We Make It Easy To Track All Users Analytics - Brandymail.com"
                                className="image cover"
                                height={0}
                                width={0}
                              />
                            </div>
                          </div>
                        </div>
                      </motion.div>
                    </div>
                    <div className="w-layout-grid grid-2-columns text-right-default v2">
                      <motion.div
                        className="inner-container _571px _100---tablet"
                        initial={{ opacity: 0, x: -100 }}
                        whileInView={{ opacity: 1, x: 0 }}
                        transition={{ duration: 0.5, delay: 0.5 }}
                        viewport={{ once: true }}
                      >
                        <div className="position-relative">
                          <div className="inner-container">
                            <div className="image-wrapper border-radius-18px">
                              <Image
                                src="/images/brandymail-bimi-vmc-home6.svg"
                                loading="eager"
                                alt="Custom-Made Reports To Make Great Decisions - Brandymail.com"
                                className="image cover"
                                height={0}
                                width={0}
                              />
                            </div>
                          </div>
                        </div>
                      </motion.div>
                      <motion.div
                        className="inner-container _570px _100---tablet"
                        initial={{ opacity: 0, x: 100 }}
                        whileInView={{ opacity: 1, x: 0 }}
                        transition={{ duration: 0.5, delay: 0.7 }}
                        viewport={{ once: true }}
                      >
                        <div className="text-center---tablet">
                          <div className="mg-bottom-24px">
                            <div className="text-100 bold color-accent-1 text-uppercase">
                              Inbox Authority
                            </div>
                          </div>
                          <h2 className="display-2">Cut Through Inbox Junk</h2>
                          <p className="mg-bottom-40px paragraph-medium">
                            Separate yourself from all the inbox noise. Scammers and bad actors
                            can't get a VMC, but you can!
                          </p>
                          <div className="buttons-row center---tablet">
                            <AnimatedButton
                              href="/products/digicert-verified-mark-certificate"
                              target="_blank"
                              text="Get Started"
                            />
                          </div>
                        </div>
                      </motion.div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      <section className="section pd-bottom-53px pd-top-0px section-video">
        <div className="container-default w-container">
          <div className="position-relative z-index-1">
            <div className="mg-bottom-24px mg-top-80px">
              <div className="inner-container _877px center">
                <div className="inner-container _600px---mbl center">
                  <div className="text-center">
                    <div className="mg-bottom-24px">
                      <div className="text-100 bold color-accent-1 text-uppercase">
                        DIGICERT EXPLAINER VIDEO
                      </div>
                    </div>
                    <h2 className="display-2">Learn how you can take back control of the inbox.</h2>
                    <div className="inner-container _712px center">
                      <p className="paragraph-medium">
                        See how our verified mark certificates from DigiCert increase user trust,
                        expand brand visibility, improve customer experience, and amplify
                        engagement.
                      </p>
                      <Image
                        src="/images/logo-digicert-blue.svg"
                        alt="DigiCert Logo"
                        loading="lazy"
                        height={84}
                        width={200}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mg-bottom-80px">
              <div className="image-wrapper video-thumbnail">
                <Image
                  src="/images/brandymail-vmc-video-thumbnail.png"
                  loading="eager"
                  alt="Brandymail Is Trusted By 25,000+ Happy Users Around The World - Brandymail.com"
                  className="image cover video-image"
                  height={1220}
                  width={650}
                />
                <button className="video-btn w-inline-block" onClick={showModal}>
                  <Image
                    src="/images/icon-video-button-techkit-template.svg"
                    loading="lazy"
                    alt="Play video"
                    className="video-btn-icon"
                    height={100}
                    width={100}
                  />
                </button>
                <div className="video-filter"></div>
              </div>
            </div>
            <div className="logo-strips-wrapper opacity-40">
              <div className="logo-23-mobile">
                <Image
                  src="/images/logo-white-1-companies-techkit-template.svg"
                  alt="Google - Brandymail.com"
                  height={0}
                  width={0}
                />
              </div>
              <div className="logo-23-mobile">
                <Image
                  src="/images/logo-white-2-companies-techkit-template.svg"
                  alt="Facebook - Brandymail.com"
                  height={0}
                  width={0}
                />
              </div>
              <div className="logo-23-mobile">
                <Image
                  src="/images/logo-white-3-companies-techkit-template.svg"
                  alt="YouTube - Brandymail.com"
                  height={0}
                  width={0}
                />
              </div>
              <div className="logo-23-mobile">
                <Image
                  src="/images/logo-white-4-companies-techkit-template.svg"
                  alt="Pinterest - Brandymail.com"
                  height={0}
                  width={0}
                />
              </div>
              <div className="logo-23-mobile">
                <Image
                  src="/images/logo-white-5-companies-techkit-template.svg"
                  alt="Twitch - Brandymail.com"
                  height={0}
                  width={0}
                />
              </div>
            </div>
          </div>
          <div className="position-absolute card-bottom-bg---top"></div>
        </div>
      </section>
      <section className="section pd-0px position-relative blue-callout">
        <div className="container-default w-container">
          <div className="cta-v2-wrapper">
            <div className="position-relative z-index-2">
              <div className="inner-container _581px">
                <h2 className="display-2 color-neutral-100">
                  Ready to get started?
                  <br />
                  Create an account today
                </h2>
                <p className="color-neutral-200 mg-bottom-40px paragraph-medium">
                  Enhance the trust and recognition of your emails with visually verified branding.
                  It&apos;s time to make a bold statement in every inbox.
                </p>
                <div className="buttons-row">
                  <AnimatedButton
                    href="/products/digicert-verified-mark-certificate"
                    target="_blank"
                    text="Get Started"
                  />
                  <Link href="/contact" className="btn-secondary white w-button">
                    Talk to Sales
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="position-absolute cta-v2-grid-wrapper animateGrid">
          <div className="position-absolute cta-v2-grid-fade">
            <AnimatedGrid />
          </div>
        </div>
        <div className="position-absolute cta-v2-bg"></div>
        <div className="svg-logo-container">
          <Image
            src="/images/bm-symbol-with-check-dark.png"
            alt="Logo"
            className="svg-logo"
            height={500}
            width={500}
          />
        </div>
      </section>
      <Modal
        title="DigiCert VMC Product Reveal"
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        width="80%"
      >
        <video ref={videoRef} width="100%" controls autoPlay muted>
          <source
            src="https://www.digicert.com/content/dam/digicert/videos/digicert-vmc-product-reveal.mp4"
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>
      </Modal>
    </div>
  );
};

export default Home;
